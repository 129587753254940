import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const ConfirmRemovalDialog = ({ isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle sx={{ fontSize: '20px' }}>
        {t('confirmRemoval.title')}
      </DialogTitle>
      <DialogContent sx={{ lineHeight: '120%' }}>
        <Divider sx={{ marginBottom: '20px' }} />
        {t('confirmRemoval.message')}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='secondary' variant='outlined'>
          {t('confirmRemoval.cancel')}
        </Button>
        <Button onClick={handleConfirm} color='primary' variant='outlined'>
          {t('confirmRemoval.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmRemovalDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmRemovalDialog;
