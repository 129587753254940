import { useState, useEffect } from 'react';
import belecoApi from '../../../../../api';

const useBooking = (bookingId) => {
  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const updateBooking = async (payload) => {
    setLoading(true);
    setError(null);

    try {
      const data = await belecoApi.inv.updateBooking(bookingId, payload);
      setBooking(data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchBooking = async () => {
      if (!bookingId) return;

      try {
        const data = await belecoApi.inv.getBooking(bookingId);
        setBooking(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchBooking();
  }, [bookingId]);

  return { booking, loading, error, updateBooking };
};

export default useBooking;
