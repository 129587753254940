import { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import Instances from '../Instances';
import InstanceDetails from '../InstanceDetails';
import ProductDetails from '../ProductDetails';
import WarehouseDeliveries from '../WarehouseDeliveries';
import WarehouseDeliveryDetails from '../WarehouseDeliveryDetails';
import BatchOrders from '../BatchOrders';
import Transports from '../Transports';
import TransportDetails from '../TransportDetails';
import Products from '../Products';
import Payouts from '../Payouts';
import PayoutDetails from '../PayoutDetails';
import { HEADER_HEIGHT } from '../../utils/constants';
import ROLES, { hasRole, ROLES_LINKS } from '../../utils/roles';
import LocationProvider from '../../context/Location/LocationProvider';
import TMSProvider from '../../context/TMS/TMSContext';
import Navigation from './Navigation';
import Dashboard from '../Dashboard';
import BookingDetailedView from '../InstanceDetails/components/Bookings/BookingDetailedView';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '100%',
    backgroundColor: '#ececec',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  content: {
    flexGrow: 1,
    marginTop: HEADER_HEIGHT,
    padding: '25px 20px',
    overflowY: 'scroll',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
}));

const Main = () => {
  const classes = useStyles();
  const userData = useSelector((state) => state.auth.user);
  const location = useLocation();

  const returnRoleLink = () => {
    if (hasRole(userData?.roles, ROLES.ADMIN)) return ROLES_LINKS.ADMIN;
    if (hasRole(userData?.roles, ROLES.WO_WORKER)) return ROLES_LINKS.WO_WORKER;
    return ROLES_LINKS.TRANSPORT_CARRIER;
  };

  useEffect(() => {
    Userpilot.reload();
  }, [location]);

  return (
    <LocationProvider>
      <div className={classes.container}>
        <Navigation />
        <main className={classes.content}>
          <TMSProvider>
            <Switch>
              <Route exact path='/dashboard' component={Dashboard} />
              <Route exact path='/products' component={Products} />
              <Route exact path='/instances' component={Instances} />
              <Route
                exact
                path='/warehouse-orders'
                component={WarehouseDeliveries}
              />
              <Route exact path='/payouts' component={Payouts} />
              <Route path='/instances/:publicId' component={InstanceDetails} />
              <Route
                path='/bookings/:bookingId'
                component={BookingDetailedView}
              />
              <Route path='/products/:productId' component={ProductDetails} />
              <Route
                path='/warehouse-orders/:publicId'
                component={WarehouseDeliveryDetails}
              />
              <Route path='/other-tools' component={BatchOrders} />
              <Route
                path='/transports/:publicId'
                component={TransportDetails}
              />
              <Route path='/payouts/:payoutBasisId' component={PayoutDetails} />
              <Route exact path='/transports' component={Transports} />
              <Redirect to={returnRoleLink()} />
            </Switch>
          </TMSProvider>
        </main>
      </div>
    </LocationProvider>
  );
};

export default Main;
